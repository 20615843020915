var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.dataLength > 4 ? "portalItemMin" : "portalItemMax",
      style: {
        background: "url(" + require("" + _vm.imgSrc) + ") center / cover",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "itemShow",
          on: {
            click: function ($event) {
              _vm.ifLink
                ? _vm.rootParent.portalClickHandle(_vm.item.link)
                : _vm.noAu()
            },
          },
        },
        [_c("div", { staticClass: "moduleNames" }, [_vm._t("bottom")], 2)]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }