var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.data.length > 4 ? "portalMain" : "portalMainTop",
      style: { width: _vm.elementWidth + "px" },
    },
    [
      _vm._l(_vm.data, function (item, ind) {
        return [
          _c("PortalItem", {
            key: ind,
            attrs: {
              ifLink: item.ifLink,
              top: item.top,
              item: item,
              imgSrc: item.imgSrc,
              dataLength: _vm.data.length,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "bottom",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          class:
                            _vm.data.length > 4
                              ? "moduleNameMin"
                              : "moduleNameMax",
                        },
                        [_vm._v(_vm._s(item.mName))]
                      ),
                      _c(
                        "div",
                        {
                          class:
                            _vm.data.length > 4
                              ? "englishNameMin"
                              : "englishNameMax",
                        },
                        [_vm._v(_vm._s(item.eName))]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }